<template>
  <el-dialog
    title="修改密码"
    :visible.sync="dialogVisible"
    center
    width="500px"
    :before-close="handleClose">

    <el-form label-width="80px">
      <el-form-item label="用户">
        {{userId}}
      </el-form-item>
      <el-form-item label="新密码">
        <el-input v-model="postForm.password" placeholder="填写新密码"/>
      </el-form-item>
    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleSubmit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { requestHttp } from "@/api/main";

export default {
  name: "DialogResetPassword",
  data() {
    return {
      dialogVisible: false,
      userId: 0,
      messageId: '',
      datas: [],
      activeTabsName: 'first',
      postForm: {
        userId: '',
        password: ''
      }
    }
  },
  methods: {
    toggle(userId) {
      this.userId = userId
      this.postForm.userId = userId
      this.dialogVisible = true
    },
    handleClose() {
      this.dialogVisible = false
    },
    handleSubmit() {
      const that = this
      this.$confirm('确认修改？',  '提示').then(function () {
        requestHttp('agent/reset_password', that.postForm).then((res) => {
          if (res.code == 200) {
            that.$message.success(res.message)
            that.handleClose()
          } else {
            that.$message.error(res.message)
          }
        })
      })
    },
  }
}
</script>

<style scoped>
  .green {
    color: #35b65a !important;
  }
  .red {
    color: #ff494a !important;
  }
</style>
