<template>
  <div>
    <el-form :inline="true" :model="postForm" class="demo-form-inline">
      <el-form-item label="UserID">
        <el-input v-model="postForm.user_id" placeholder="UserID"></el-input>
      </el-form-item>
      <el-form-item label="Status">
        <el-select v-model="postForm.status" placeholder="状态">
          <el-option label="ALL" value="">ALL</el-option>
          <el-option label="Pending" value="1">Pending</el-option>
          <el-option label="Submitted" value="2">Submitted</el-option>
          <el-option label="Refuse" value="3">Refuse</el-option>
          <el-option label="Pass" value="4">Pass</el-option>
          <el-option label="VirtualPass" value="5">VirtualPass</el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-date-picker
          v-model="postForm.date_range"
          type="daterange"
          range-separator="TO"
          start-placeholder="StartDay"
          end-placeholder="EndDay">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="fetchData">Search</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :max-height="maxHeight"
      :data="datas"
      border
      :show-summary="true"
    >
      <el-table-column
        prop="user_id"
        label="UserID"
        width="120">
      </el-table-column>
      <el-table-column
        prop="line_id"
        label="Line"
        width="120">
      </el-table-column>
      <el-table-column
        prop="create_time"
        label="ApplyTime"
        width="100">
      </el-table-column>
      <el-table-column
        prop="money"
        label="Money"
        width="120">
      </el-table-column>
      <el-table-column
        prop="copy"
        label="Show/Copy"
        width="120">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="dark" placement="left">
            <div slot="content" style="padding: 10px; font-size: 14px;">
              {{scope.row.u_bank_name}}<br/>{{scope.row.u_bank_card}}<br/>{{scope.row.u_bank_address}}<br/>{{scope.row.u_real_name}}<br/>T{{scope.row.money}}<br/><br/>{{scope.row.user_id}}
            </div>
            <a>Show</a>
          </el-tooltip>
          | <a @click="copyVal(scope.row)" style="color: blue">Copy</a>
        </template>
      </el-table-column>
      <el-table-column
        prop="status_en_str"
        label="Status"
        width="80">
      </el-table-column>
      <el-table-column
        width="120"
        label="操作">
        <template slot-scope="scope">
          <el-button-group>
            <el-button type="primary" size="mini" v-if="scope.row.status === 1" @click="handlePass(scope.row.id)">Submit
            </el-button>
            <el-button type="warning" size="mini" v-if="scope.row.status === 1 || scope.row.status === 3" @click="handleShowDuplicateCheck(scope.row.user_id)">
              Check
            </el-button>
          </el-button-group>
        </template>
      </el-table-column>
      <el-table-column
        prop="submit_time"
        label="SubmitTime"
        width="100">
      </el-table-column>
      <el-table-column
        prop="extract_time"
        label="PassTime"
        width="100">
      </el-table-column>
      <el-table-column
        prop="audit_user"
        label="Operator"
        width="100">
      </el-table-column>
    </el-table>
    <br />
    <el-pagination
      background
      layout="prev, pager, next, sizes"
      :page-sizes="[20, 100, 300, 400, 5000]"
      :current-page.sync="postForm.page"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      :total="postForm.total"
      :page-size="postForm.limit">
    </el-pagination>
    <dialog-order-log ref="dialogOrderLog"></dialog-order-log>
    <dialog-reset-password ref="dialogResetPassword"></dialog-reset-password>
    <dialog-duplicate-check ref="dialogDuplicateCheck"/>
  </div>
</template>

<script>
import DialogOrderLog from "@/views/chat/components/dialog-order-log";
import DialogResetPassword from "@/views/manage/components/dialog-reset-password";
import ComponentAgent from "@/components/component-agent";
import DialogDuplicateCheck from "@/views/chat/components/dialog-duplicate-check";
import { requestHttp } from "@/api/main";
import { thumb } from "@/utils/utils";

export default {
  name: "ComponentFirstWithdraw",
  components: {
    DialogOrderLog, DialogResetPassword, ComponentAgent,DialogDuplicateCheck
  },
  data() {
    return {
      maxHeight: 500,
      postForm: {
        agents: [],
        user_id: '',
        status: '',
        date_range: [],
        is_first: 1,
        page: 1,
        total: 0,
        limit: 20
      },
      agents: [],
      datas: []
    }
  },
  created() {
    this.maxHeight = window.innerHeight - 220;
    this.fetchData()
  },
  methods: {
    thumb,
    handleAgentsChange(e) {
      this.postForm.agents = e
      this.fetchData()
    },
    handleShowDuplicateCheck(userId) {
      this.$refs.dialogDuplicateCheck.toggle(userId)
    },
    // 复制操作
    copyVal(row) {
      let context = row.user_id + ' 出' + row.money
      // 创建输入框元素
      let oInput = document.createElement('textarea');
      // 将想要复制的值
      oInput.innerHTML = context;
      // 页面底部追加输入框
      document.body.appendChild(oInput);
      // 选中输入框
      oInput.select();
      // 执行浏览器复制命令
      document.execCommand('Copy');
      // 弹出复制成功信息
      this.$message.success('复制成功');
      // 复制后移除输入框
      oInput.remove();
    },
    fetchData() {
      const that = this
      requestHttp('agent/get_withdraw_log', this.postForm).then((res) => {
        if (res.code != 200) {
          that.$message.error(res.message)
        } else {
          this.datas = res.data.data
          this.agents = res.data.agents
          this.postForm.page = res.data.current_page
          this.postForm.total = res.data.total
          this.postForm.limit = res.data.per_page
        }
      })
    },
    handleCurrentChange(e) {
      console.log(e)
      this.fetchData()
    },
    handleSizeChange(val) {
      this.postForm.page = 1
      this.postForm.limit = val
      this.fetchData()
    },
    handlePass(id)
    {
      const that = this
      this.$confirm('确定通过此出金？',  '提示').then(function () {
        requestHttp('agent/pass_withdraw', {
          'id': id
        }).then((res) => {
          if (res.code == 200) {
            that.$message.success(res.message)
            that.fetchData()
          } else {
            that.$message.error(res.message)
          }
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
