<template>
  <div>
    <el-form :inline="true" :model="postForm" class="demo-form-inline">
      <el-form-item label="代理">
        <component-agent @change="handleAgentsChange"></component-agent>
      </el-form-item>
      <el-form-item label="">
        <el-date-picker
          v-model="postForm.day"
          type="date"
          value-format="yyyy-MM-dd"
          start-placeholder="日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="fetchData">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="datas"
      :max-height="maxHeight"
      border
      :show-summary="true"
      :summary-method="handleSummary"
    >
      <el-table-column
        type="index"
        prop="id"
        label="序号"
        width="80">
      </el-table-column>
      <el-table-column
        v-for="(item, idx) in colums"
        :key="idx"
        :prop="item.key"
        :label="item.title"
        :width="item.width">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import ComponentAgent from "@/components/component-agent";
import { requestHttp } from "@/api/main";
import { getCurrentDay, thumb } from "@/utils/utils";

export default {
  name: "ComponentAgentStatisticsDay",
  components: {
    ComponentAgent
  },
  data() {
    return {
      maxHeight: 500,
      postForm: {
        agents: [],
        day: getCurrentDay(),
        page: 1,
        total: 0,
        limit: 20
      },
      colums: [],
      calcs: [],
      datas: []
    }
  },
  created() {
    this.maxHeight = window.innerHeight - 100;
    this.fetchData()
  },
  methods: {
    thumb,
    handleAgentsChange(e) {
      this.postForm.agents = e
      this.fetchData()
    },
    fetchData() {
      const that = this
      requestHttp('agent/agent_day_statistics', this.postForm).then((res) => {
        if (res.code != 200) {
          that.$message.error(res.message)
        } else {
          this.datas = res.data.datas
          that.colums = res.data.headers
          that.calcs = res.data.calcs
        }
      })
    },
    handleSummary(param) {
      const { columns, data } = param;
      const sums = {};
      const that = this;
      columns.forEach((column, index) => {
        const key = column.property;
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        if (that.calcs.length > 0 && that.calcs.includes(key)) {
          const values = data.map(item => Number(item[key]));
          const v = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = v;
        } else {
          sums[index] = '';
        }
      });
      console.log(sums)

      return sums;
    }
  }
}
</script>

<style scoped>
.green {
  color: green;
}
.red {
  color: red;
}
</style>
