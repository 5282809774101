<template>
  <div>
    <el-form :inline="true" :model="postForm" class="demo-form-inline">
      <el-form-item label="代理线">
        <component-agent @change="handleAgentsChange"></component-agent>
      </el-form-item>
      <el-form-item label="用户名">
        <el-input v-model="postForm.user_id" placeholder="用户名"></el-input>
      </el-form-item>
      <el-form-item label="代理">
        <el-input v-model="postForm.affiliate_name" placeholder="代理"></el-input>
      </el-form-item>
      <el-form-item label="类型">
        <el-select v-model="postForm.action_type" placeholder="类型">
          <el-option label="全部类型" value="">全部类型</el-option>
          <el-option label="交易" value="1">交易</el-option>
          <el-option label="提现" value="5">提现</el-option>
          <el-option label="彩金" value="2">彩金</el-option>
<!--          <el-option label="彩金机" value="3">彩金机</el-option>-->
<!--          <el-option label="客服机" value="7">客服机</el-option>-->
          <el-option label="客服" value="4">客服</el-option>
          <el-option label="管理员" value="6">管理员</el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-date-picker
          v-model="postForm.date_range"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="postForm.note" placeholder="备注"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="fetchData">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="datas"
      :max-height="maxHeight"
      border
      :show-summary="true"
    >
      <el-table-column
        prop="action_type_str"
        label="类型"
        width="80">
      </el-table-column>
      <el-table-column
        prop="user_id"
        label="用户"
        width="120">
      </el-table-column>
      <el-table-column
        prop="line_id"
        label="代理线"
        width="120">
        <template slot-scope="scope">
          {{scope.row.line_id}}
        </template>
      </el-table-column>
      <el-table-column
        prop="action_time"
        label="时间"
        width="180">
      </el-table-column>
      <el-table-column
        prop="money"
        label="金额"
        width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.method_type == 1" class="green">+{{scope.row.money}}</span>
          <span v-else class="red">{{scope.row.money}}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="note"
        label="备注">
      </el-table-column>
    </el-table>
    <br />
    <el-pagination
      background
      layout="prev, pager, next, sizes"
      :page-sizes="[20, 100, 300, 400, 5000]"
      :current-page.sync="postForm.page"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      :total="postForm.total"
      :page-size="postForm.limit">
    </el-pagination>
    <dialog-order-log ref="dialogOrderLog"></dialog-order-log>
    <dialog-reset-password ref="dialogResetPassword"></dialog-reset-password>
  </div>
</template>

<script>
import DialogOrderLog from "@/views/chat/components/dialog-order-log";
import DialogResetPassword from "@/views/manage/components/dialog-reset-password";
import ComponentAgent from "@/components/component-agent";
import { requestHttp } from "@/api/main";
import { thumb } from "@/utils/utils";

export default {
  name: "ComponentAccount",
  components: {
    DialogOrderLog, DialogResetPassword, ComponentAgent
  },
  data() {
    return {
      maxHeight: 500,
      postForm: {
        agents: [],
        user_id: '',
        status: '',
        note: '',
        method_type: '',
        action_type: '',
        ip: '',
        bank_card_no: '',
        page: 1,
        total: 0,
        limit: 20
      },
      agents: [],
      datas: []
    }
  },
  created() {
    this.maxHeight = window.innerHeight - 220;
    this.fetchData()
  },
  methods: {
    thumb,
    handleAgentsChange(e) {
      this.postForm.agents = e
      this.fetchData()
    },
    fetchData() {
      const that = this
      requestHttp('agent/get_money_log', this.postForm).then((res) => {
        if (res.code != 200) {
          that.$message.error(res.message)
        } else {
          this.datas = res.data.data
          this.agents = res.data.agents
          this.postForm.page = res.data.current_page
          this.postForm.total = res.data.total
          this.postForm.limit = res.data.per_page
        }
      })
    },
    handleCurrentChange(e) {
      console.log(e)
      this.fetchData()
    },
    handleSizeChange(val) {
      this.postForm.page = 1
      this.postForm.limit = val
      this.fetchData()
    },
    showOrderLog(userId) {
      this.$refs.dialogOrderLog.toggle(userId)
    },
    showResetPassword(userId) {
      this.$refs.dialogResetPassword.toggle(userId)
    },
    handleSwitchStatus(userId, status) {
      const statusArr = {
        0: '锁定账户',
        1: '恢复账户'
      }
      const that = this
      this.$confirm('确认设置用户状态为' + statusArr[status] + '？',  '提示').then(function () {
        requestHttp('agent/switch_status', {
          'user_id': userId,
          'status': status
        }).then((res) => {
          if (res.code == 200) {
            that.$message.success(res.message)
            that.fetchData()
          } else {
            that.$message.error(res.message)
          }
        })
      })
    },
  }
}
</script>

<style scoped>
.green {
  color: green;
}
.red {
  color: red;
}
</style>
