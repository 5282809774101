<template>
  <div>
    <el-form :inline="true" :model="postForm" class="demo-form-inline">
      <el-form-item label="代理线">
        <component-agent @change="handleAgentsChange"></component-agent>
      </el-form-item>
      <el-form-item label="用户名">
        <el-input v-model="postForm.user_id" placeholder="用户名"></el-input>
      </el-form-item>
      <el-form-item label="代理">
        <el-input v-model="postForm.affiliate_name" placeholder="代理"></el-input>
      </el-form-item>
      <el-form-item label="状态">
        <el-select v-model="postForm.status" placeholder="状态">
          <el-option label="全部状态" value="">全部状态</el-option>
          <el-option label="待审" value="1">待审</el-option>
          <el-option label="已提交" value="2">已提交</el-option>
          <el-option label="已拒绝" value="3">已拒绝</el-option>
          <el-option label="通过" value="4">通过</el-option>
          <el-option label="假通过" value="5">假通过</el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-date-picker
          v-model="postForm.date_range"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="fetchData">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :max-height="maxHeight"
      :data="datas"
      border
      :show-summary="true"
    >
      <el-table-column
        prop="user_id"
        label="用户"
        width="120">
      </el-table-column>
      <el-table-column
        prop="line_id"
        label="代理线"
        width="120">
      </el-table-column>
      <el-table-column
        prop="create_time"
        label="申请时间"
        width="100">
      </el-table-column>
      <el-table-column
        prop="money"
        label="金额"
        width="120">
      </el-table-column>
      <el-table-column
        prop="code"
        label="提款码"
        width="80">
      </el-table-column>
      <el-table-column
        prop="copy"
        label="复制"
        width="120">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="dark" placement="left">
            <div slot="content" style="padding: 10px; font-size: 14px;">
              AccountNumber:{{scope.row.u_bank_card}}<br/>IFSC: {{scope.row.u_bank_name}}<br/>AccountName: {{scope.row.u_real_name}}<br/>BranchName: {{scope.row.u_bank_address}}<br/>UPI: {{scope.row.u_upi}}<br/>出：{{scope.row.money}}<br/><br/>{{scope.row.user_id}}
            </div>
            <a>显示</a>
          </el-tooltip>
          | <a @click="copyVal(scope.row)" style="color: blue">复制</a>
        </template>
      </el-table-column>
      <el-table-column
        prop="status_str"
        label="状态"
        width="80">
      </el-table-column>
      <el-table-column
        width="260"
        label="操作">
        <template slot-scope="scope">
          <el-button-group>
            <div v-if="scope.row.status == 3 && scope.row.reason != ''">理由：{{scope.row.reason}}</div>
            <el-button type="primary" size="mini" v-if="scope.row.status === 1" @click="handlePass(scope.row.id)">提交
            </el-button>
            <el-button type="warning" size="mini" v-if="scope.row.status === 1" @click="handleRefuse(scope.row.id)">
              拒绝
            </el-button>
            <el-button type="danger" size="mini" v-if="scope.row.status === 1" @click="handleOver(scope.row.id)">
              假通过
            </el-button>
            <el-button type="danger" size="mini" v-if="scope.row.status === 5" @click="handleRecover(scope.row.id)">
              恢复
            </el-button>
            <el-button type="danger" size="mini" v-if="scope.row.status === 3" @click="handleEditReason(scope.row.id)">
              修改
            </el-button>
            <el-button type="warning" size="mini" v-if="scope.row.status === 1 || scope.row.status === 3" @click="handleShowDuplicateCheck(scope.row.user_id)">
              查重
            </el-button>
          </el-button-group>
        </template>
      </el-table-column>
      <el-table-column
        prop="submit_time"
        label="提交时间"
        width="100">
      </el-table-column>
      <el-table-column
        prop="extract_time"
        label="通过时间"
        width="100">
      </el-table-column>
      <el-table-column
        prop="audit_user"
        label="操作人">
      </el-table-column>
    </el-table>
    <br />
    <el-pagination
      background
      layout="prev, pager, next, sizes"
      :page-sizes="[20, 100, 300, 400, 5000]"
      :current-page.sync="postForm.page"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      :total="postForm.total"
      :page-size="postForm.limit">
    </el-pagination>
    <dialog-order-log ref="dialogOrderLog"></dialog-order-log>
    <dialog-reset-password ref="dialogResetPassword"></dialog-reset-password>
    <dialog-duplicate-check ref="dialogDuplicateCheck"/>
  </div>
</template>

<script>
import DialogOrderLog from "@/views/chat/components/dialog-order-log";
import DialogResetPassword from "@/views/manage/components/dialog-reset-password";
import ComponentAgent from "@/components/component-agent";
import DialogDuplicateCheck from "@/views/chat/components/dialog-duplicate-check";
import { requestHttp } from "@/api/main";
import { thumb } from "@/utils/utils";

export default {
  name: "ComponentAccount",
  components: {
    DialogOrderLog, DialogResetPassword, ComponentAgent,DialogDuplicateCheck
  },
  data() {
    return {
      maxHeight: 500,
      postForm: {
        agents: [],
        user_id: '',
        status: '',
        date_range: [],
        page: 1,
        total: 0,
        limit: 20
      },
      agents: [],
      datas: []
    }
  },
  created() {
    this.maxHeight = window.innerHeight - 220;
    this.fetchData()
  },
  methods: {
    thumb,
    handleAgentsChange(e) {
      this.postForm.agents = e
      this.fetchData()
    },
    handleShowDuplicateCheck(userId) {
      this.$refs.dialogDuplicateCheck.toggle(userId)
    },
    // 复制操作
    copyVal(row) {
      let context = 'AccountNumber:'+ row.u_bank_card + '\nIFSC: '+ row.u_bank_name + 'AccountName: '+ row.u_real_name + '\nBranchName: '+ row.u_bank_address + '\nUPI: '+ row.u_upi + '\n出' + row.money
      // 创建输入框元素
      let oInput = document.createElement('textarea');
      // 将想要复制的值
      oInput.innerHTML = context;
      // 页面底部追加输入框
      document.body.appendChild(oInput);
      // 选中输入框
      oInput.select();
      // 执行浏览器复制命令
      document.execCommand('Copy');
      // 弹出复制成功信息
      this.$message.success('复制成功');
      // 复制后移除输入框
      oInput.remove();
    },
    fetchData() {
      const that = this
      requestHttp('agent/get_withdraw_log', this.postForm).then((res) => {
        if (res.code != 200) {
          that.$message.error(res.message)
        } else {
          this.datas = res.data.data
          this.agents = res.data.agents
          this.postForm.page = res.data.current_page
          this.postForm.total = res.data.total
          this.postForm.limit = res.data.per_page
        }
      })
    },
    handleCurrentChange(e) {
      console.log(e)
      this.fetchData()
    },
    handleSizeChange(val) {
      this.postForm.page = 1
      this.postForm.limit = val
      this.fetchData()
    },
    handlePass(id)
    {
      const that = this
      this.$confirm('确定通过此出金？',  '提示').then(function () {
        requestHttp('agent/pass_withdraw', {
          'id': id
        }).then((res) => {
          if (res.code == 200) {
            that.$message.success(res.message)
            that.fetchData()
          } else {
            that.$message.error(res.message)
          }
        })
      })
    },
    handleRefuse(id)
    {
      const that = this
      this.$prompt('请输入拒绝理由', '操作', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: '',
        inputPlaceholder: '这个是显示客户看的，别输入中文',
      }).then(({ value }) => {
        requestHttp('agent/refuse_withdraw', {
          'id': id,
          reason: value
        }).then((res) => {
          if (res.code == 200) {
            that.$message.success(res.message)
            that.fetchData()
          } else {
            that.$message.error(res.message)
          }
        })
      })
    },
    handleEditReason(id)
    {
      const that = this
      this.$prompt('请输入新的拒绝理由', '操作', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: '',
        inputPlaceholder: '拒绝理由',
      }).then(({ value }) => {
        requestHttp('agent/refuse_withdraw_edit_reason', {
          'id': id,
          reason: value
        }).then((res) => {
          if (res.code == 200) {
            that.$message.success(res.message)
            that.fetchData()
          } else {
            that.$message.error(res.message)
          }
        })
      })
    },
    handleOver(id)
    {
      const that = this
      this.$confirm('确定设置为假通过？',  '提示').then(function () {
        requestHttp('agent/virtual_over_withdraw', {
          'id': id
        }).then((res) => {
          if (res.code == 200) {
            that.$message.success(res.message)
            that.fetchData()
          } else {
            that.$message.error(res.message)
          }
        })
      })
    },
    handleAllOver()
    {
      const that = this
      this.$confirm('确定将所有状态为待审核的设置为假通过？',  '提示').then(function () {
        requestHttp('agent/virtual_all_over_withdraw', {user_id: that.userId}).then((res) => {
          if (res.code == 200) {
            that.$message.success(res.message)
            that.fetchData()
          } else {
            that.$message.error(res.message)
          }
        })
      })
    },
    handleRecover(id)
    {
      const that = this
      this.$confirm('确定恢复为待审状态？',  '提示').then(function () {
        requestHttp('agent/recover_withdraw', {
          'id': id
        }).then((res) => {
          if (res.code == 200) {
            that.$message.success(res.message)
            that.fetchData()
          } else {
            that.$message.error(res.message)
          }
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
