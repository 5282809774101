<template>
  <div>
    <el-form :inline="true" :model="postForm" class="demo-form-inline">
      <el-form-item label="代理线">
        <component-agent @change="handleAgentsChange"></component-agent>
      </el-form-item>
      <el-form-item label="用户名">
        <el-input v-model="postForm.user_id" placeholder="用户名"></el-input>
      </el-form-item>
      <el-form-item label="状态">
        <el-select v-model="postForm.status" placeholder="状态">
          <el-option label="全部" value="">全部</el-option>
          <el-option label="进行中" value="1">进行中</el-option>
          <el-option label="已完成" value="2">已完成</el-option>
          <el-option label="失败" value="3">失败</el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="fetchData">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="datas"
      :max-height="maxHeight"
      border
      :show-summary="true"
    >
      <el-table-column
        prop="status_str"
        label="状态"
        width="80">
      </el-table-column>
      <el-table-column
          prop="req_no"
          label="订单号"
          width="200">
      </el-table-column>
      <el-table-column
        prop="user_id"
        label="用户"
        width="120">
      </el-table-column>
      <el-table-column
        prop="line_id"
        label="代理线"
        width="120">
        <template slot-scope="scope">
          {{scope.row.line_id}}
        </template>
      </el-table-column>
      <el-table-column
        prop="create_time"
        label="时间"
        width="180">
      </el-table-column>
      <el-table-column
        prop="amount"
        label="金额"
        width="120">
      </el-table-column>
      <el-table-column
        prop="note"
        label="备注">
      </el-table-column>
    </el-table>
    <br />
    <el-pagination
      background
      layout="prev, pager, next, sizes"
      :page-sizes="[20, 100, 300, 400, 5000]"
      :current-page.sync="postForm.page"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      :total="postForm.total"
      :page-size="postForm.limit">
    </el-pagination>
  </div>
</template>

<script>
import ComponentAgent from "@/components/component-agent";
import { requestHttp } from "@/api/main";
import { thumb } from "@/utils/utils";

export default {
  name: "ComponentRecharge",
  components: {
    ComponentAgent
  },
  data() {
    return {
      maxHeight: 500,
      postForm: {
        agents: [],
        user_id: '',
        status: '',
        note: '',
        page: 1,
        total: 0,
        limit: 20
      },
      agents: [],
      datas: []
    }
  },
  created() {
    this.maxHeight = window.innerHeight - 220;
    this.fetchData()
  },
  methods: {
    thumb,
    handleAgentsChange(e) {
      this.postForm.agents = e
      this.fetchData()
    },
    fetchData() {
      const that = this
      requestHttp('agent/get_recharge_log', this.postForm).then((res) => {
        if (res.code != 200) {
          that.$message.error(res.message)
        } else {
          this.datas = res.data.data
          this.agents = res.data.agents
          this.postForm.page = res.data.current_page
          this.postForm.total = res.data.total
          this.postForm.limit = res.data.per_page
        }
      })
    },
    handleCurrentChange(e) {
      console.log(e)
      this.fetchData()
    },
    handleSizeChange(val) {
      this.postForm.page = 1
      this.postForm.limit = val
      this.fetchData()
    }
  }
}
</script>

<style scoped>
.green {
  color: green;
}
.red {
  color: red;
}
</style>
