<template>
  <div>
    <el-button @click="showTree = !showTree">{{ title }}</el-button>
    <el-dialog
      title="选择代理线"
      :visible.sync="showTree"
      center
      width="500px">
      <div style="max-height: 500px; overflow-y: auto">
        <el-input
          placeholder="输入关键字进行过滤"
          v-model="filterText">
        </el-input>
        <el-tree
          :data="agents"
          ref="tree"
          show-checkbox
          node-key="id"
          :default-expand-all="false"
          :check-strictly="true"
          :expand-on-click-node="false"
          :props="{children: 'children', label: 'label'}"
          :filter-node-method="filterNode"
          @check-change="handleChange"
        >
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <span>{{ node.label }}</span>
            <span v-if="data.children && data.children.length > 0">
              <el-button
                type="text"
                size="mini"
                @click="() => checkedChild(data)">
                选中下级
              </el-button>
              <el-button
                type="text"
                size="mini"
                @click="() => removeCheckedChild(data)">
                取消选中
              </el-button>
            </span>
          </span>
        </el-tree>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleSave">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { requestHttp } from "@/api/main";
export default {
  name: "ComponentAgent",
  data() {
    return {
      title: '全部代理线',
      showTree: false,
      filterText: '',
      expanded: [],
      agents: [],
      checked: []
    }
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  created() {
    this.fetchAllAgents()
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    fetchAllAgents()
    {
      const that = this
      requestHttp('agent/get_all_agents', {}).then((res) => {
        if (res.code != 200) {
          that.$message.error(res.message)
        } else {
          this.agents = res.data.agents
          this.expanded = res.data.expanded
        }
      })
    },
    checkedChild(e) {
      let arr = this.$refs.tree.getCheckedKeys()
      arr.push(e.id)
      e.children.forEach(item1 => {
        arr.push(item1.id)
        if (item1.children && item1.children.length > 0) {
          arr = [...arr, ...this.getAllChildren(item1)]
        }
      })
      return this.$refs.tree.setCheckedKeys(arr);
    },
    removeCheckedChild(e) {
      let arr = this.$refs.tree.getCheckedKeys()
      let allChild = this.getAllChildren(e)
      allChild.push(e.id)
      arr = arr.filter(item => {
        return !allChild.includes(item)
      })
      return this.$refs.tree.setCheckedKeys(arr);
    },
    getAllChildren(data) {
      let arr = []
      data.children.forEach(item1 => {
        arr.push(item1.id)
        if (item1.children && item1.children.length > 0) {
          arr = [...arr, ...this.getAllChildren(item1)]
        }
      })
      return arr
    },
    handleChange(e, check) {
      if (check) {
        this.checked.push(e)
      } else {
        const idx = this.checked.indexOf(e)
        if (idx !== -1) {
          this.checked.splice(idx, 1)
        }
      }
    },
    handleClose() {
      this.showTree = false
    },
    handleSave() {
      if (this.checked.length == 0) {
        this.title = '全部代理线'
      } else if (this.checked.length == 1) {
        this.title = this.checked[0].label
      } else {
        this.title = this.checked[0].label + '等'
      }
      let ids = []
      this.checked.forEach(item => {
        ids.push(item.id)
      })

      this.$emit('change', ids)
      this.handleClose()
    }
  }
}
</script>

<style scoped>
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
</style>
