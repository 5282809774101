<template>
  <el-container id="app-menu">
    <el-aside
      theme="light"
      class="layout-sider"
      width="150px"
    >
      <div :class="{'list-item': true, 'active': current == 'account'}" @click="current = 'account'">
        会员管理
      </div>
      <div :class="{'list-item': true, 'active': current == 'order'}" @click="current = 'order'">
        订单报表
      </div>
      <div :class="{'list-item': true, 'active': current == 'deposit'}" @click="current = 'deposit'">
        充币报表
      </div>
      <div :class="{'list-item': true, 'active': current == 'recharge'}" @click="current = 'recharge'">
        充值日志
      </div>
      <div :class="{'list-item': true, 'active': current == 'withdraw'}" @click="current = 'withdraw'">
        提币报表
      </div>
      <div :class="{'list-item': true, 'active': current == 'agent_statistics_day'}" @click="current = 'agent_statistics_day'">
        报数日报
      </div>
      <div :class="{'list-item': true, 'active': current == 'agent_statistics_month'}" @click="current = 'agent_statistics_month'">
        报数月报
      </div>
      <div :class="{'list-item': true, 'active': current == 'example'}" @click="current = 'example'">
        案例学习
      </div>
      <div :class="{'list-item': true, 'active': current == 'first-withdraw'}" @click="current = 'first-withdraw'">
        FirstWithdrawal
      </div>
      <div :class="{'list-item': true, 'active': current == 'other-account'}" @click="current = 'other-account'">
        MemberList
      </div>
    </el-aside>
    <el-main class="main-wrap">
      <div v-if="current == 'account'">
        <component-account ref="componentAccount"/>
      </div>

      <div v-if="current == 'deposit'">
        <component-deposit ref="componentDeposit"/>
      </div>

      <div v-if="current == 'recharge'">
        <component-recharge ref="componentRecharge"/>
      </div>

      <div v-if="current == 'order'">
        <component-order ref="componentOrder"/>
      </div>

      <div v-if="current == 'withdraw'">
        <component-withdraw ref="componentWithdraw"/>
      </div>

      <div v-if="current == 'first-withdraw'">
        <component-first-withdraw ref="componentFirstWithdraw"/>
      </div>
      <div v-if="current == 'other-account'">
        <component-other-account ref="componentOtherAccount"/>
      </div>
      <div v-if="current == 'example'">
        <component-example ref="componentExample"/>
      </div>
      <div v-if="current == 'agent_statistics_day'">
        <component-agent-statistics-day ref="componentAgentStatisticsDay"/>
      </div>
      <div v-if="current == 'agent_statistics_month'">
        <component-agent-statistics-month ref="componentAgentStatisticsMonth"/>
      </div>
    </el-main>
  </el-container>
</template>
<script>

import {
  mapState
} from 'vuex'
import { dateTimeFormat, thumb, blobToBase64 } from '@/utils/utils'
import ComponentAccount from "@/views/manage/components/component-account";
import ComponentOtherAccount from "@/views/manage/components/component-other-account";
import ComponentOrder from "@/views/manage/components/component-order";
import ComponentWithdraw from "@/views/manage/components/component-withdraw";
import ComponentFirstWithdraw from "@/views/manage/components/component-first-withdraw";
import ComponentDeposit from "@/views/manage/components/component-deposit";
import ComponentRecharge from "@/views/manage/components/component-recharge";
import ComponentAgentStatisticsDay from '@/views/manage/components/component-agent-statistics-day';
import ComponentAgentStatisticsMonth from '@/views/manage/components/component-agent-statistics-month';
import ComponentExample from '@/views/manage/components/component-example'

export default {
  name: 'Manage',
  components: {
    ComponentAccount, ComponentOrder, ComponentWithdraw,
    ComponentDeposit, ComponentRecharge, ComponentFirstWithdraw, ComponentOtherAccount,
    ComponentAgentStatisticsDay, ComponentAgentStatisticsMonth,
    ComponentExample
  },
  data() {
    return {
      current: 'account',
      isLoad: true
    };
  },
  computed: {
    ...mapState({
      user: state => state.yeim.user,
      friendList: state => state.yeim.friendList,
      friendApplyUnread: state => state.yeim.friendApplyUnread,
    }),
    friendListFilter() {
      return this.$store.state.yeim.friendList
    }
  },
  mounted() {
    this.$refs.componentAccount.fetchAccount()
  },
  watch: {
    // current(val) {
    //   if (val == 'account') {
    //     this.$refs.componentAccount.fetchAccount()
    //   }
    // }
  },
  methods: {
    dateTimeFormat,
    thumb
  }
};
</script>
<style lang="less" scoped>
#app-menu {
  height: 100%;

  .layout-sider {
    border-top: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
    background-color: #FAFAFA;
    overflow: auto;
    padding-top: 50px;
  }

  .scroll-box {
    flex: 1;
    height: 500px;
    overflow: hidden;
  }

  .list-item {
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 12px 30px;
    height: 40px;
    line-height: 40px;
    box-sizing: border-box;
    border-left: 3px solid transparent;
    cursor: pointer;
    text-align: left;

    &:hover {
      background: #EFF0F1;
    }

    &.active {
      background: #EFF0F1;
      border-left: 3px solid #1890FF;
    }
  }
  .main-wrap {
    padding: 20px;

    .avatar-upload {
      text-align: center;
      padding-bottom: 20px;
    }
  }
}

</style>
