<template>
  <div>
    <el-form :inline="true" :model="postForm" class="demo-form-inline">
      <el-form-item label="代理线">
        <component-agent @change="handleAgentsChange"></component-agent>
      </el-form-item>
      <el-form-item label="用户名">
        <el-input v-model="postForm.user_id" placeholder="用户名"></el-input>
      </el-form-item>
      <el-form-item label="代理">
        <el-input v-model="postForm.affiliate_name" placeholder="代理"></el-input>
      </el-form-item>
      <el-form-item label="状态">
        <el-select v-model="postForm.settle_status" placeholder="状态">
          <el-option label="全部状态" value=""></el-option>
          <el-option label="待结算" value="1"></el-option>
          <el-option label="已结算" value="2"></el-option>
          <el-option label="盈利" value="3"></el-option>
          <el-option label="亏损" value="4"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-date-picker
          v-model="postForm.date_range"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="fetchData">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table :max-height="maxHeight"  border :data="datas">
      <el-table-column
        prop="user_id"
        label="用户"
        width="140">
        <template slot-scope="scope">
          {{scope.row.user_id}}<br>{{scope.row.nickname}}
        </template>
      </el-table-column>
      <el-table-column
        prop="line_id"
        label="代理线"
        width="90">
        <template slot-scope="scope">
          {{scope.row.line_id}}
        </template>
      </el-table-column>
      <el-table-column
        prop="create_time"
        label="单号/日期"
        width="180">
        <template slot-scope="scope">
          <span style="color: #000;">{{scope.row.order_sn}}</span><br>
          <span style="font-size: 12px">{{scope.row.create_time}}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="trade_status"
        label="交易状态"
        align="center"
        width="80">
        <template slot-scope="scope">
          <el-popover
            placement="right"
            width="80"
            trigger="click"
            :placement="`bottom-start`"
            v-if="scope.row.status == 1"
          >
            <el-button type="success" @click="handleSwitchTradeStatus(scope.row, 3)" size="mini" style="margin-bottom: 5px">盈利</el-button><br>
            <el-button type="warning" @click="handleSwitchTradeStatus(scope.row, 4)" size="mini">亏损</el-button>
            <el-button slot="reference" :type="scope.row.trade_status_type" size="mini" v-popover:popoverUser>
              {{ scope.row.trade_status_str }}
            </el-button>
          </el-popover>
          <div v-else>{{ scope.row.trade_status_str }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="title"
        label="交易币"
        width="120">
      </el-table-column>
      <el-table-column
        prop="amount"
        label="规格"
        width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.buy_up === 1" style="color: green">涨 {{scope.row.time}} S</span>
          <span v-else style="color: red">跌 {{scope.row.time}} S</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="amount"
        label="金额"
        width="120">
      </el-table-column>
      <el-table-column
        prop="chain_price"
        label="即时价"
        width="120">
      </el-table-column>
      <el-table-column
        prop="settle_price"
        label="结算价"
        width="120">
      </el-table-column>
      <el-table-column
        prop="status_str"
        label="状态">
      </el-table-column>
      <el-table-column
        prop="earning"
        label="收益">
      </el-table-column>
    </el-table>
    <br />
    <el-pagination
      background
      layout="prev, pager, next, sizes"
      :page-sizes="[20, 100, 300, 400, 5000]"
      :current-page.sync="postForm.page"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      :total="postForm.total"
      :page-size="postForm.limit">
    </el-pagination>
    <dialog-order-log ref="dialogOrderLog"></dialog-order-log>
    <dialog-reset-password ref="dialogResetPassword"></dialog-reset-password>
  </div>
</template>

<script>
import DialogOrderLog from "@/views/chat/components/dialog-order-log";
import DialogResetPassword from "@/views/manage/components/dialog-reset-password";
import ComponentAgent from "@/components/component-agent";
import { requestHttp } from "@/api/main";
import { thumb } from "@/utils/utils";

export default {
  name: "ComponentAccount",
  components: {
    DialogOrderLog, DialogResetPassword, ComponentAgent
  },
  data() {
    return {
      maxHeight: 500,
      postForm: {
        agents: [],
        user_id: '',
        affiliate_name: '',
        settle_status: '',
        page: 1,
        total: 0,
        limit: 20
      },
      agents: [],
      datas: []
    }
  },
  created() {
    this.maxHeight = window.innerHeight - 220;
    this.fetchData()
  },
  methods: {
    thumb,
    handleAgentsChange(e) {
      this.postForm.agents = e
      this.fetchData()
    },
    fetchData() {
      const that = this
      requestHttp('agent/get_order_log', this.postForm).then((res) => {
        if (res.code != 200) {
          that.$message.error(res.message)
        } else {
          this.datas = res.data.data
          this.agents = res.data.agents
          this.postForm.page = res.data.current_page
          this.postForm.total = res.data.total
          this.postForm.limit = res.data.per_page
        }
      })
    },
    handleCurrentChange(e) {
      console.log(e)
      this.fetchData()
    },
    handleSizeChange(val) {
      this.postForm.page = 1
      this.postForm.limit = val
      this.fetchData()
    },
    showOrderLog(userId) {
      this.$refs.dialogOrderLog.toggle(userId)
    },
    showResetPassword(userId) {
      this.$refs.dialogResetPassword.toggle(userId)
    },
    handleSwitchStatus(userId, status) {
      const statusArr = {
        0: '锁定账户',
        1: '恢复账户'
      }
      const that = this
      this.$confirm('确认设置用户状态为' + statusArr[status] + '？',  '提示').then(function () {
        requestHttp('agent/switch_status', {
          'user_id': userId,
          'status': status
        }).then((res) => {
          if (res.code == 200) {
            that.$message.success(res.message)
            that.fetchData()
          } else {
            that.$message.error(res.message)
          }
        })
      })
    },
    handleSwitchTradeStatus(row, status) {
      const statusArr = {
        3: '盈利',
        4: '亏损',
      }
      const that = this
      this.$confirm('确认设置交易状态为' + statusArr[status] + '？', '提示').then(function () {
        requestHttp('agent/switch_order_trade_status', {
          'id': row.id,
          'trade_status': status
        }).then((res) => {
          if (res.code == 200) {
            that.$message.success({ message: res.message, offset: 100 })
            that.fetchData()
          } else {
            that.$message.error({ message: res.message, offset: 100 })
          }
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
