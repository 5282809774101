<template>
  <div>
    <el-form :inline="true" :model="postForm" class="demo-form-inline">
      <el-form-item label="标题">
        <el-input v-model="postForm.example_title" placeholder="标题"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="fetchData">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table :max-height="maxHeight"  border :data="datas">
      <el-table-column
        prop="example_time"
        label="时间"
        width="160">
        <template slot-scope="scope">
          {{scope.row.example_time}}
        </template>
      </el-table-column>
      <el-table-column
        prop="user_id"
        label="老师"
        width="90">
        <template slot-scope="scope">
          {{scope.row.user_id}}
        </template>
      </el-table-column>
      <el-table-column
        prop="title"
        label="客户"
        width="150">
        <template slot-scope="scope">
          {{scope.row.title}}
        </template>
      </el-table-column>
      <el-table-column
        prop="example_title"
        label="标题">
        <template slot-scope="scope">
          {{scope.row.example_title}}
        </template>
      </el-table-column>
      <el-table-column
        prop="earning"
        label="操作"
        width="150">
        <template slot-scope="scope">
          <el-button-group>
            <el-button size="mini" type="primary" @click="showExample(scope.row)">查看</el-button>
            <el-button v-if="scope.row.user_id == user.userId" size="mini" type="danger" @click="cancelShare(scope.row)">取消</el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
    <br />
    <el-pagination
      background
      layout="prev, pager, next, sizes"
      :page-sizes="[20, 100, 300, 400, 5000]"
      :current-page.sync="postForm.page"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      :total="postForm.total"
      :page-size="postForm.limit">
    </el-pagination>
    <dialog-example ref="dialogExample"></dialog-example>
  </div>
</template>

<script>
import DialogExample from "@/views/manage/components/dialog-example";
import { requestHttp } from "@/api/main";
import { thumb } from "@/utils/utils";
import { mapState } from "vuex";

export default {
  name: "ComponentExample",
  components: {
    DialogExample
  },
  data() {
    return {
      maxHeight: 500,
      postForm: {
        example_title: '',
        page: 1,
        total: 0,
        limit: 20
      },
      agents: [],
      datas: []
    }
  },
  computed:{
    ...mapState({
      user: state => state.yeim.user
    }),
  },
  created() {
    this.maxHeight = window.innerHeight - 220;
    this.fetchData()
  },
  methods: {
    thumb,
    handleAgentsChange(e) {
      this.postForm.agents = e
      this.fetchData()
    },
    fetchData() {
      const that = this
      requestHttp('agent/get_example', this.postForm).then((res) => {
        if (res.code != 200) {
          that.$message.error(res.message)
        } else {
          this.datas = res.data.data
          this.postForm.page = res.data.current_page
          this.postForm.total = res.data.total
          this.postForm.limit = res.data.per_page
        }
      })
    },
    handleCurrentChange(e) {
      console.log(e)
      this.fetchData()
    },
    handleSizeChange(val) {
      this.postForm.page = 1
      this.postForm.limit = val
      this.fetchData()
    },
    showExample(row) {
      this.$refs.dialogExample.toggle(row.user_id, row.conversation_id)
    },
    cancelShare(row) {
      const that = this
      this.$confirm('确定取消分享 ' + row.title,  '提示').then(function () {
        requestHttp('agent/cancel_example_share', {
          'conversation_id': row.conversationId
        }).then((res) => {
          if (res.code == 200) {
            that.$message.success(res.message)
            that.fetchData()
          } else {
            that.$message.error(res.message)
          }
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
