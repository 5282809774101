<template>
  <div>
    <el-form :inline="true" :model="postForm" class="demo-form-inline">
      <el-form-item label="代理">
        <component-agent @change="handleAgentsChange"></component-agent>
      </el-form-item>
      <el-form-item label="">
        <el-date-picker
          v-model="postForm.day"
          type="month"
          value-format="yyyy-MM">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="">
        <el-select v-model="postForm.channel_id" placeholder="渠道">
          <el-option label="全部" value="all"></el-option>
          <el-option
            v-for="(item, idx) in channels"
            :key="idx"
            :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="fetchData">查询</el-button>
      </el-form-item>
    </el-form>
    <el-tabs v-model="active">
      <el-tab-pane label="进粉数" name="name1">
        <el-table :data="datas"
          :max-height="maxHeight"
          border
          highlight-row>
          <el-table-column
            type="index"
            prop="id"
            label="序号"
            width="80">
          </el-table-column>
          <el-table-column
            v-for="(item, idx) in columns1"
            :key="idx"
            :prop="item.key"
            :label="item.title"
            :width="item.width">
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="三方数" name="name2">
        <el-table :data="datas"
          :max-height="maxHeight"
          border
          highlight-row>
          <el-table-column
            type="index"
            prop="id"
            label="序号"
            width="80">
          </el-table-column>
          <el-table-column
            v-for="(item, idx) in columns5"
            :key="idx"
            :prop="item.key"
            :label="item.title"
            :width="item.width">
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="三方率" name="name3">
        <el-table :data="datas"
          :max-height="maxHeight"
          border
          highlight-row>
          <el-table-column
            type="index"
            prop="id"
            label="序号"
            width="80">
          </el-table-column>
          <el-table-column
            v-for="(item, idx) in columns6"
            :key="idx"
            :prop="item.key"
            :label="item.title"
            :width="item.width">
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="任务数" name="name4">
        <el-table :data="datas"
          :max-height="maxHeight"
          border
          highlight-row>
          <el-table-column
            type="index"
            prop="id"
            label="序号"
            width="80">
          </el-table-column>
          <el-table-column
            v-for="(item, idx) in columns3"
            :key="idx"
            :prop="item.key"
            :label="item.title"
            :width="item.width">
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="任务率" name="name5">
        <el-table :data="datas"
          :max-height="maxHeight"
          border
          highlight-row>
          <el-table-column
            type="index"
            prop="id"
            label="序号"
            width="80">
          </el-table-column>
          <el-table-column
            v-for="(item, idx) in columns4"
            :key="idx"
            :prop="item.key"
            :label="item.title"
            :width="item.width">
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="广告费" name="name7">
        <el-table :data="datas"
          :max-height="maxHeight"
          border
          highlight-row>
          <el-table-column
            type="index"
            prop="id"
            label="序号"
            width="80">
          </el-table-column>
          <el-table-column
            v-for="(item, idx) in columns7"
            :key="idx"
            :prop="item.key"
            :label="item.title"
            :width="item.width">
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import ComponentAgent from "@/components/component-agent";
import { requestHttp } from "@/api/main";
import { getCurrentMonth, thumb } from "@/utils/utils";

export default {
  name: "ComponentAgentStatisticsMonth",
  components: {
    ComponentAgent
  },
  data() {
    return {
      maxHeight: 500,
      postForm: {
        agents: [],
        day: getCurrentMonth(),
        page: 1,
        total: 0,
        limit: 20
      },
      active: 'name7',
      channels: [],
      columns1: [],
      columns2: [],
      columns3: [],
      columns4: [],
      columns5: [],
      columns6: [],
      columns7: [],
      datas: []
    }
  },
  created() {
    this.maxHeight = window.innerHeight - 100;
    this.fetchData()
  },
  methods: {
    thumb,
    handleAgentsChange(e) {
      this.postForm.agents = e
      this.fetchData()
    },
    fetchData() {
      const that = this
      requestHttp('agent/agent_month_statistics', this.postForm).then((res) => {
        if (res.code != 200) {
          that.$message.error(res.message)
        } else {
          let data = res.data;
          that.channels = data.channels
          that.columns1 = data.headers1
          that.columns2 = data.headers2
          that.columns3 = data.headers3
          that.columns4 = data.headers4
          that.columns5 = data.headers5
          that.columns6 = data.headers6
          that.columns7 = data.headers7
          this.datas = data.datas;
        }
      })
    }
  }
}
</script>

<style scoped>
.green {
  color: green;
}
.red {
  color: red;
}
</style>
