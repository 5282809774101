<template>
  <div>
    <el-form :inline="true" :model="postForm" class="demo-form-inline">
      <el-form-item label="代理线">
        <component-agent @change="handleAgentsChange"></component-agent>
      </el-form-item>
      <el-form-item label="用户名">
        <el-input v-model="postForm.user_id" placeholder="用户名"></el-input>
      </el-form-item>
      <el-form-item label="代理">
        <el-input v-model="postForm.affiliate_name" placeholder="代理"></el-input>
      </el-form-item>
      <el-form-item label="IP">
        <el-input v-model="postForm.ip" placeholder="IP"></el-input>
      </el-form-item>
      <br/>
      <el-form-item label="银行卡号">
        <el-input v-model="postForm.bank_card_no" placeholder="银行卡号"></el-input>
      </el-form-item>
      <el-form-item label="状态">
        <el-select v-model="postForm.status" placeholder="状态">
          <el-option label="全部" value="0"></el-option>
          <el-option label="正常" value="1"></el-option>
          <el-option label="锁定" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="fetchAccount">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table :max-height="maxHeight"  border :data="datas">
      <el-table-column
        prop="avatar"
        label="头像"
        align="center"
        width="80">
        <template slot-scope="scope">
          <el-image lazy :src="thumb(scope.row.thumb_avatar)" style="width: 40px; height: 40px;" :preview-src-list="[thumb(scope.row.avatar)]"></el-image>
        </template>
      </el-table-column>
      <el-table-column
        prop="user_id"
        label="账号"
        align="center"
        width="180">
        <template slot-scope="scope">
          {{scope.row.user_id}} ({{scope.row.nickname}})
        </template>
      </el-table-column>
      <el-table-column
        prop="score"
        label="信誉分"
        align="center"
        width="90">
        <template slot-scope="scope">
          <el-input v-model="scope.row.score" @change="handleScoreEdit(scope.row)" style="width: 60px"/>
        </template>
      </el-table-column>
      <el-table-column
        prop="copy"
        label="复制"
        width="120">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="dark" placement="left">
            <div slot="content" style="padding: 10px; font-size: 14px;">
              {{scope.row.bank_name}}<br/>{{scope.row.bank_card}}<br/>{{scope.row.bank_address}}<br/>{{scope.row.real_name}}<br/><br/>{{scope.row.user_id}}
            </div>
            <a>显示</a>
          </el-tooltip>
          | <a @click="copyVal(scope.row)" style="color: blue">复制</a>
        </template>
      </el-table-column>
      <el-table-column
        prop="status"
        label="状态"
        align="center"
        width="80">
        <template slot-scope="scope">
          <span v-if="scope.row.status == 1">正常</span>
          <span v-else style="color: red">锁定</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="bind_account_status"
        label="绑定开关"
        align="center"
        width="80">
        <template slot-scope="scope">
          <el-popover
            placement="right"
            width="80"
            trigger="click"
            :placement="`bottom-start`"
          >
            <el-button type="success" @click="handleSwitchBindAccountStatus(scope.row, 1)" size="mini" style="margin-bottom: 5px">开启</el-button><br>
            <el-button type="danger" @click="handleSwitchBindAccountStatus(scope.row, 0)" size="mini" style="margin-bottom: 5px">关闭</el-button><br>
            <el-button slot="reference" :type="scope.row.bind_account_status_type" size="mini" v-popover:popoverUser>
              {{ scope.row.bind_account_status_str }}
            </el-button>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column
        prop="status"
        label="交易状态"
        align="center"
        width="80">
        <template slot-scope="scope">
          <el-popover
            placement="right"
            width="80"
            trigger="click"
            :placement="`bottom-start`"
          >
            <el-button type="primary" @click="handleSwitchTradeStatus(scope.row, 1)" size="mini" style="margin-bottom: 5px">正常</el-button><br>
            <el-button type="danger" @click="handleSwitchTradeStatus(scope.row, 2)" size="mini" style="margin-bottom: 5px">禁止</el-button><br>
            <el-button type="success" @click="handleSwitchTradeStatus(scope.row, 3)" size="mini" style="margin-bottom: 5px">盈利</el-button><br>
            <el-button type="warning" @click="handleSwitchTradeStatus(scope.row, 4)" size="mini">亏损</el-button>
            <el-button slot="reference" :type="scope.row.trade_status_type" size="mini" v-popover:popoverUser>
              {{ scope.row.trade_status_str }}
            </el-button>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column
        prop="line_id"
        label="代理线"
        align="center"
        width="120">
        <template slot-scope="scope">
          {{scope.row.line_id}}
        </template>
      </el-table-column>
      <el-table-column
        prop="create_time"
        label="注册日期"
        width="160">
      </el-table-column>

      <el-table-column
        prop="balance"
        label="余额"
        align="center"
        width="120">
      </el-table-column>
      <el-table-column
        prop="freeze_balance"
        label="锁定金额"
        align="center"
        width="120">
      </el-table-column>
      <el-table-column
        prop="freeze_task_money"
        label="本金"
        align="center"
        width="120">
      </el-table-column>
      <el-table-column
        prop="freeze_task_commission"
        label="收益"
        align="center"
        width="120">
      </el-table-column>
      <el-table-column
        prop="register_ip"
        label="注册IP"
        width="180">
        <template slot-scope="scope">
          <el-button type="text" @click="showUserById(scope.row.register_ip)">{{scope.row.register_ip}}</el-button>
        </template>
      </el-table-column>
      <el-table-column
        prop="last_login_ip"
        label="登陆IP"
        width="180">
        <template slot-scope="scope">
          <el-button type="text" @click="showUserById(scope.row.last_login_ip)">{{scope.row.last_login_ip}}</el-button>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        :width="220"
        label="操作">
        <template v-slot="scope">
          <el-button-group>
            <el-button type="warning" size="mini" @click="showResetPassword(scope.row.user_id)">重置密码</el-button>
            <el-button type="danger" size="mini" v-if="scope.row.status == 1" @click="handleSwitchStatus(scope.row.user_id, 0)">锁定账户</el-button>
            <el-button type="primary" size="mini" v-else @click="handleSwitchStatus(scope.row.user_id, 1)">解锁</el-button>
            <el-button type="primary" size="mini" @click="showOrderLog(scope.row.user_id)">订单</el-button>
            <el-button type="warning" size="mini" @click="showGift(scope.row.user_id)">彩金</el-button>
            <el-button type="primary" size="mini" @click="showSendMessage(scope.row.user_id)">消息</el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
    <br />
    <el-pagination
      background
      layout="prev, pager, next, sizes"
      :page-sizes="[20, 100, 300, 400, 5000]"
      :current-page.sync="postForm.page"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      :total="postForm.total"
      :page-size="postForm.limit">
    </el-pagination>
    <dialog-order-log ref="dialogOrderLog"></dialog-order-log>
    <dialog-reset-password ref="dialogResetPassword"></dialog-reset-password>
    <dialog-gift ref="dialogGift"></dialog-gift>
    <dialog-user-by-ip ref="dialogUserByIp"></dialog-user-by-ip>
    <dialog-send-message ref="dialogSendMessage"></dialog-send-message>
  </div>
</template>

<script>
import DialogOrderLog from "@/views/chat/components/dialog-order-log";
import DialogResetPassword from "@/views/manage/components/dialog-reset-password";
import ComponentAgent from "@/components/component-agent";
import DialogGift from "@/views/manage/components/dialog-gift";
import DialogUserByIp from "@/views/manage/components/dialog-user-by-ip";
import DialogSendMessage from "@/views/manage/components/dialog-send-message";
import { requestHttp } from "@/api/main";
import { thumb } from "@/utils/utils";

export default {
  name: "ComponentAccount",
  components: {
    DialogOrderLog, DialogResetPassword, ComponentAgent, DialogGift, DialogUserByIp, DialogSendMessage
  },
  data() {
    return {
      maxHeight: 500,
      postForm: {
        agents: [],
        user_id: '',
        status: '',
        ip: '',
        bank_card_no: '',
        page: 1,
        total: 0,
        limit: 20
      },
      agents: [],
      datas: []
    }
  },
  created() {
    this.maxHeight = window.innerHeight - 220;
    this.fetchAccount()
  },
  methods: {
    thumb,
    handleAgentsChange(e) {
      this.postForm.agents = e
      this.fetchAccount()
    },
    fetchAccount() {
      const that = this
      requestHttp('agent/get_accounts', this.postForm).then((res) => {
        if (res.code != 200) {
          that.$message.error(res.message)
        } else {
          this.datas = res.data.data
          this.agents = res.data.agents
          this.postForm.page = res.data.current_page
          this.postForm.total = res.data.total
          this.postForm.limit = res.data.per_page
        }
      })
    },
    handleScoreEdit(row) {
      const that = this
      requestHttp('agent/save_score', {
        'user_id': row.user_id,
        'score': row.score
      }).then((res) => {
        if (res.code == 200) {
          that.$message.success({ message: res.message, offset: 100 })
          that.showScoreEdit = false
        } else {
          that.$message.error({ message: res.message, offset: 100 })
        }
      })
    },
    handleCurrentChange(e) {
      console.log(e)
      this.fetchAccount()
    },
    handleSizeChange(val) {
      this.postForm.page = 1
      this.postForm.limit = val
      this.fetchAccount()
    },
    showOrderLog(userId) {
      this.$refs.dialogOrderLog.toggle(userId)
    },
    showResetPassword(userId) {
      this.$refs.dialogResetPassword.toggle(userId)
    },
    showUserById(ip) {
      this.$refs.dialogUserByIp.toggle(ip)
    },
    showGift(userId) {
      this.$refs.dialogGift.toggle(userId)
    },
    showSendMessage(userId) {
      this.$refs.dialogSendMessage.toggle(userId)
    },
    // 复制操作
    copyVal(row) {
      let context = row.bank_name + '\n' + row.bank_card + '\n' + row.bank_address + '\n' + row.real_name + '\n\n' + row.user_id
      // 创建输入框元素
      let oInput = document.createElement('textarea');
      // 将想要复制的值
      oInput.innerHTML = context;
      // 页面底部追加输入框
      document.body.appendChild(oInput);
      // 选中输入框
      oInput.select();
      // 执行浏览器复制命令
      document.execCommand('Copy');
      // 弹出复制成功信息
      this.$message.success('复制成功');
      // 复制后移除输入框
      oInput.remove();
    },
    handleSwitchStatus(userId, status) {
      const statusArr = {
        0: '锁定账户',
        1: '恢复账户'
      }
      const that = this
      this.$confirm('确认设置用户状态为' + statusArr[status] + '？',  '提示').then(function () {
        requestHttp('agent/switch_status', {
          'user_id': userId,
          'status': status
        }).then((res) => {
          if (res.code == 200) {
            that.$message.success(res.message)
            that.fetchAccount()
          } else {
            that.$message.error(res.message)
          }
        })
      })
    },
    handleSwitchTradeStatus(row, status) {
      const statusArr = {
        1: '正常',
        2: '禁止交易',
        3: '盈利',
        4: '亏损',
      }
      const that = this
      this.$confirm('确认设置交易状态为' + statusArr[status] + '？', '提示').then(function () {
        requestHttp('agent/switch_trade_status', {
          'user_id': row.user_id,
          'trade_status': status
        }).then((res) => {
          if (res.code == 200) {
            that.$message.success({ message: res.message, offset: 100 })
            that.fetchAccount()
          } else {
            that.$message.error({ message: res.message, offset: 100 })
          }
        })
      })
    },
    handleSwitchBindAccountStatus(row, status) {
      const statusArr = {
        0: '关闭',
        1: '开启'
      }
      const that = this
      this.$confirm('确认设置绑定开关为' + statusArr[status] + '？', '提示').then(function () {
        requestHttp('agent/switch_bind_account_status', {
          'user_id': row.user_id,
          'status': status
        }).then((res) => {
          if (res.code == 200) {
            that.$message.success({ message: res.message, offset: 100 })
            that.fetchAccount()
          } else {
            that.$message.error({ message: res.message, offset: 100 })
          }
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
