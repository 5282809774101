<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    center
    width="1000px"
    top="10px"
    :before-close="handleClose">
    <div class="content-wrapper">
      <div class="chat-content">
        <div style="overflow-x: auto">
          <div id="chat_title" class="chat-title"
            style="display: flex; justify-content: left; min-width: 400px; ">
            <div class="nickname" v-if="conversation.type" style="width: 40px;">
              <el-image width="100"
                :src="thumb(conversation.type == 'private' ? conversation.userInfo.thumb_avatar : conversation.groupInfo.thumb_avatar)"
              />
            </div>

            <div class="des" v-if="conversation.type && conversation.type == 'private'">
              <div>
                <div style=" font-size: 12px">
                  <!--<div>{{ conversation.userInfo.user_id }} ({{ conversation.userInfo.nickname }})</div>-->
                  <div style="color: red; font-size: 12px">{{conversation.example_title}}</div>
                </div>
              </div>
            </div>
            <div class="des group" v-else>
              <!--<div style="width: 100%" v-if="conversation.groupInfo">{{ conversation.groupInfo.name }}</div>-->
              <div style="color: red; font-size: 12px">{{conversation.example_title}}</div>
            </div>
          </div>

          <div class="chat-body">
            <div id="chat_scroll" ref="chatScroll" :style="`padding: 10px; overflow-y: auto`">
              <div id="chat_scroll_content">
                <div v-loading="isLoad" element-loading-text="加载中……" t>
                </div>
                <div v-if="datas.length > 0" class="chat-item" v-for="(item,index) in datas" :key="index"
                  :class="[{'right-box': conversation.user_id == item.from}]"
                  :id="`chat_${item.sequence}`">
                  <!--              <div class="time">{{ dateTimeFormat(item.time, true) }}</div>-->
                  <div v-if="item.type == 'system'" style="color: #1d42ab; font-size: 14px">
                    <div class="txt-wrapper pad16" v-html="parseText(item.body.text)"></div>
                  </div>
                  <div class="flex-box" v-if="item.type != 'system'">
                    <div class="avatar">
                      <el-image lazy :src="thumb(item.fromUserInfo.thumb_avatar)" />
                      <div class="remark-nickname"
                        v-if="conversation.is_main == 1 && item.fromUserInfo.remarkNickname != ''">
                        {{ item.fromUserInfo.remarkNickname }}
                      </div>
                    </div>
                    <div class="msg-content">
                      <div class="name" v-if="conversation.type == 'group'"
                        @click="handleAddTribute(item.from, item.fromUserInfo.nickname)">
                        {{ item.fromUserInfo.nickname }}
                      </div>
                      <div class="msg-wrapper">
                        <div v-if="item.replyMessage && item.replyMessage.type !== ''" class="txt-wrapper pad16"
                          style="background: #f5f5f5;">
                          <div style="border-left: 2px solid #ccc; padding-left: 10px; color: #999; font-size: 12px;">
                            <div style="font-weight: bold; padding: 5px 0;">
                              {{ item.replyMessage.from }}：
                            </div>
                            <div
                              style="width: 100%; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; text-align: left">
                              <div style="white-space: nowrap" v-if="item.replyMessage.type == 'text'">
                                <div v-html="item.replyMessage.body.text"></div>
                              </div>
                              <el-image v-if="item.replyMessage.type == 'image'"
                                :src="thumb(item.replyMessage.body.thumbnailUrl)" style="height: 30px"
                                :preview-src-list="[thumb(item.replyMessage.body.originalUrl)]" />
                              <div v-if="item.replyMessage.type == 'audio'">语音（{item.replyMessage.body.duration}秒）
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- 文档 -->
                        <template v-if="item.type == 'text'">
                          <div class="txt-wrapper">
                            <div>
                              <span class="pad16" v-html="parseText(item.body.text)"></span>
                              <span class="time">{{ dateTimeFormat(item.time, true) }}</span>
                            </div>
                            <div class="txt-wrapper blue pad16 transfer" v-if="item.body_translate">
                              <span class="pad16" v-html="parseText(item.body_translate)"></span>
                            </div>
                          </div>
                        </template>
                        <!-- 文档 -->
                        <template v-if="item.type == 'audio'">
                      <span class="txt-wrapper pad16" style="height: 100px !important;">
                        <audio controls="controls"> <source :src="thumb(item.body.audioUrl)" type="audio/mp4" /></audio><br>{{ item.body.duration }}秒
                      </span>
                          <span class="time">{{ dateTimeFormat(item.time, true) }}</span>
                        </template>
                        <!-- 文档 -->
                        <template v-if="item.type == 'system'">
                          <span class="txt-wrapper pad16" v-html="parseText(item.body.text)"></span>
                          <span class="time">{{ dateTimeFormat(item.time, true) }}</span>
                        </template>
                        <!-- 图片 -->
                        <template v-if="item.type == 'image'">
                          <span class="img-wraper"
                            :style="{width: '50px !important', height: (50/item.body.thumbnailWidth * item.body.thumbnailHeight) + 'px !important'}">
                            <el-image :src="thumb(item.body.thumbnailUrl)"
                              :width="50" :height="50/item.body.thumbnailWidth * item.body.thumbnailHeight"
                              :preview-src-list="[thumb(item.body.originalUrl)]"
                              fit="contain"
                            />
                          </span>
                          <div class="time">{{ dateTimeFormat(item.time, true) }}</div>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div slot="footer" class="dialog-footer">
        <el-pagination
          background
          layout="prev, pager, next, sizes"
          :page-sizes="[20, 50, 100, 300, 400, 5000]"
          :current-page.sync="postForm.page"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :total="postForm.total"
          :page-size="postForm.limit">
        </el-pagination>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {
  dateTimeFormat,
  thumb,
} from '@/utils/utils'
import { requestHttp } from "@/api/main";
import face from "@/utils/face";

export default {
  name: "DialogExample",
  props: {
    showVirtualUser: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  components: {},
  data() {
    return {
      dialogVisible: false,
      title: '案例分享',
      isLoad: true,
      faces: face.faces,
      faceObject: face.faceObject,
      userId: '',
      conversationId: '',
      conversation: {
        example_title: ''
      },
      postForm: {
        user_id: '',
        conversation_id: '',
        page: 1,
        total: 0,
        limit: 50
      },
      datas: []
    }
  },
  methods: {
    thumb,
    dateTimeFormat,
    // initHeight() {
    //   this.chatHeight = window.innerHeight - document.getElementById('chat_title').offsetHeight - 70;
    // },
    handleClose() {
      this.dialogVisible = false
    },
    toggle(userId, conversationId) {
      const that = this

      this.userId = userId
      this.conversationId = conversationId
      this.postForm.user_id = userId
      this.postForm.conversation_id = conversationId
      that.dialogVisible = true

      requestHttp('agent/get_example_conversation', {
        user_id: this.userId,
        conversation_id: this.conversationId
      }).then((res) => {
        if (res.code != 200) {
          that.$message.error(res.message)
        } else {
          that.conversation = res.data.conversation
          that.title = ''// that.conversation.example_title
        }
      })
      this.getMessageList()
    },
    getMessageList() {
      this.isLoad = true
      requestHttp('agent/get_example_message', this.postForm).then((res) => {
        this.isLoad = false
        if (res.code != 200) {
          that.$message.error(res.message)
        } else {
          this.datas = res.data.data
          this.postForm.page = res.data.current_page
          this.postForm.total = res.data.total
        }
      })
    },
    handleCurrentChange(e) {
      console.log(e)
      this.getMessageList()
    },
    handleSizeChange(val) {
      this.postForm.page = 1
      this.postForm.limit = val
      this.getMessageList()
    },
    parseText(text) {
      var regex = /\[face_(.+?)\]/g;
      let emojis_char = text.match(regex);
      if (emojis_char) {
        for (let i = 0; i < emojis_char.length; i++) {
          let char = emojis_char[i];
          char = char.replace("[", "");
          char = char.replace("]", "");
          let index = char.replace('face_', '');
          if (this.faceObject[index] && this.faceObject[index].path) {
            let image = "<img style='width:20px;height:20px' src='" + thumb('/statics/face/' + this.faceObject[index].path) + "' />";
            text = text.replace(emojis_char[i], image);
          }
        }
      }
      return text.replace(/[\r\n]/g, '<br>');
    }

  }
}
</script>
<style lang="scss" scoped>
textarea.ivu-input {
  border: none;
  resize: none;
}

.content-wrapper {
  margin: 0 auto;
  background: #fff;

  .chat-content {
    width: 100%;
    height: 100%;
    border-right: 1px solid #ECECEC;
    position: relative;

    .top-buttons {
      padding: 0px 0 0px 0;
      text-align: left;
      //padding-left: 30px;
      .el-button {
        margin-bottom: 5px !important;
      }
    }

    .chat-title {
      background: #f8f8f8;
      padding: 10px 10px;
      line-height: 20px;
      display: flex;
      justify-content: left;
      text-align: left;

      .el-image {
        width: 30px;
        height: 30px;
        margin-right: 10px;
        border-radius: 4px;
      }

      .des {
        width: 100%;

        &.group {
          //display: flex;
          //justify-content: space-between;
          line-height: 30px;
        }
      }
    }

    .chat-body {
      flex: 1;
      height: calc(65vh);
      overflow-y: auto;

      .chat-item {
        margin-bottom: 10px;
        min-height: 70px;

        .flex-box {
          display: flex;
        }

        .avatar {
          width: 40px;
          height: 40px;
          margin-right: 16px;

          .el-image {
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 4px;
          }

          .remark-nickname {
            padding-top: 5px;
            font-size: 12px;
            //color: red;
          }
        }

        .msg-content {
          text-align: left;
          max-width: 80%;

          .name {
            text-align: left;
            padding-bottom: 5px;
            font-weight: bold;
            font-size: 12px;
          }

          .time {
            color: #9ea3ac;
            margin: 3px;
            word-break: keep-all;
            white-space: nowrap;
            font-size: 12px;
          }

          .read {
            margin: 0px 0 0 0;
            text-align: right;
            color: #bbb;
            font-size: 12px;

            .blue {
              color: blue;
            }

            .red {
              color: red;
            }
          }

          .mute {
            margin-left: 10px;
            margin-top: 5px;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            line-height: 20px;
            border: 1px solid #0e73e8;
            background: #0e73e8;
            color: #fff;
            text-align: center;

            &.active {
              border: 1px solid red;
              background: red;
              color: #fff;
            }
          }
        }

        .msg-wrapper {
          min-width: 40px;
          max-width: 500px;
          color: #000000;
          font-size: 14px;
          overflow: hidden;
          text-align: left;
          display: inline-block;

          .txt-wrapper {
            min-width: 80px;
            word-break: break-all;
            background: #F5F5F5;
            border-radius: 4px;
            font-size: 14px;
            padding: 8px 8px;

            &.blue {
              border-radius: 0 0 4px 4px;
              background: #ddd;
              color: blue !important;
            }
          }

          .pad16 {
            //padding: 3px 8px;
            .transfer {
              padding-top: 0px
            }
          }

          .img-wraper {
            font-size: 0px !important;
            background: #F5F5F5;
            border-radius: 4px;
            display: inline-block;
          }

          .img-wraper .el-image, .img-wraper img {
            max-width: 100%;
            height: auto;
            display: block;
            border-radius: 4px;
          }

          .method {
            text-align: center;
            background: #ddd;
            border-radius: 4px;
            padding: 0px 5px;

            &.gift {
              line-height: 30px;
              background: rgba(0, 0, 0, 0.3);
              display: flex;
              justify-content: space-between;
              padding: 0 30px;
            }
          }
        }

        &.right-box {
          .flex-box {
            flex-direction: row-reverse;

            .avatar {
              margin-right: 0;
              margin-left: 16px;
            }

            .msg-content {
              text-align: right;

              .name {
                text-align: right;
              }

              .msg-wrapper {
                //background: #CDE0FF;
                text-align: right;

                .time {
                  text-align: right;
                  color: #9ea3ac !important;
                }

                .txt-wrapper {
                  background: #d0e6fd;
                  text-align: left;
                }
              }
            }
          }

          &.gary .msg-wrapper {
            //background: #f5f5f5;
          }
        }
      }
    }

    .chat-textarea {
      display: flex;
      flex-direction: column;
      min-height: 45px;
      border-top: 1px solid #ECECEC;
      position: absolute;
      bottom: 0;
      width: 100%;
      background: #fff;

      .chat-btn-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0 0 0;

        .left-wrapper {
          display: flex;
          align-items: center;

          .icon-item {
            display: flex;
            align-items: center;
            margin-left: 20px;
            cursor: pointer;
            font-size: 24px;

            .iconfont {
              font-size: 22px;
              color: #333333;
            }
          }
        }

        .right-wrapper {
          position: relative;
          padding-right: 20px;

          .icon-item {
            display: flex;
            align-items: center;
            font-size: 15px;
            color: #333;
            cursor: pointer;

            span {
              margin-left: 10px;
            }
          }

          .transfer-box {
            z-index: 60;
            position: absolute;
            right: 1px;
            bottom: 43px;
            width: 140px;
            background: #fff;
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
            padding: 16px;
          }

          .transfer-bg {
            z-index: 50;
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: transparent;
          }
        }

        .emoji-box {
          position: absolute;
          left: 0;
          top: 0;
          transform: translateY(-100%);
          display: flex;
          flex-wrap: wrap;
          width: 60%;
          padding: 15px 9px;
          box-shadow: 0px 0px 13px 1px rgba(0, 0, 0, 0.1);
          background: #fff;

          .emoji-item {
            margin-right: 13px;
            margin-bottom: 8px;
            cursor: pointer;

            &:nth-child(10n) {
              margin-right: 0;
            }
          }
        }
      }

      .textarea-box {
        flex: 1;
        display: flex;
        justify-content: space-between;
        min-height: 0;
        text-align: left;
      }

      .editable {
        flex: 1;
        padding: 4px 7px;
        overflow-x: hidden;
        overflow-y: auto;
        font-size: 14px;
        line-height: 30px;
        min-height: 30px;
        color: #515a6e;
        background: #f5f5f5;
        border-radius: 4px;

        &:focus-visible {
          outline: 0;
        }

        margin-right: 10px;
      }

      .send-btn {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}

.right-content {
  width: 100%;
  min-width: 300px;
  //position: fixed;
  //right: 20px; top: 20px;
  //border: 2px solid cornflowerblue;
  //border-radius: 4px;
  //box-shadow: 0px 0px 13px 1px rgba(0, 0, 0, 0.5);
  //z-index: 5000;
  .close {
    position: absolute;
    top: 5px;
    right: 5px;
    display: none;
  }
}

.bg {
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}


.right_menu {
  position: relative;

  .crmchat_link {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    transition: 0.3s;
    cursor: pointer;

    span {
      color: #ccc;
    }

    span:hover {
      color: #007aff;
    }
  }
}
</style>
